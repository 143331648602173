const appConfig = {
    apiPrefix: '',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'de',
    enableMock: false,
}

export default appConfig
