"use client";

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ErrorBoundary } from "react-error-boundary";
import { apiReportError } from 'services/CommonService';
import * as Sentry from "@sentry/react";

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.warn('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.warn(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
Sentry.init({
  dsn: "https://3fccd5439f3aa7f60c515e8d5e028ab8@o4506393141248000.ingest.sentry.io/4506393319112704",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
}

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div className='text-center' role="alert">
        <p className='mt-8 text-xl'>Es ist ein Fehler aufgetreten.</p>
        <p className='mt-4 text-lg'><span className='cursor-pointer underline hover:text-black' onClick={() => {window.location.reload(true);}}>Klicken Sie hier um die Seite neu zu laden</span> oder <a href='https://partner.kde-kurier.com/' className='cursor-pointer underline hover:text-black'>oder hier um zur Startseite zurückzukehren.</a></p>
        <pre className='p-8' style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }

const logError = (error, info) => {
    let err = JSON.stringify(error, Object.getOwnPropertyNames(error))
    let url = window.location.href;

    apiReportError({err, info, url})
    console.log(error);
    console.log(info);
};

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <ErrorBoundary onError={logError} FallbackComponent={ErrorFallback}>
            <App />
        </ErrorBoundary>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
