import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { setMode } from 'store/theme/themeSlice'
import { setLang } from 'store/locale/localeSlice'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)

            if (resp.data) {
                const token = resp.data.token
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                    dispatch(setMode(resp.data.user.dark_mode ? 'dark' : 'light'));
                    dispatch(dispatch(setLang(resp.data.user.language)));

					let beamsClient;
					switch(process.env.NODE_ENV) {
						case 'production':
							beamsClient = new PusherPushNotifications.Client({
								instanceId: "57560fb0-33fa-4c79-a3c0-0518b6392872"
							});
							break;
						case 'development':
						default:
							beamsClient = new PusherPushNotifications.Client({
								instanceId: "89d5b66c-1786-4b64-a665-45ff248f45d7"
							});
					}

					beamsClient
					.start()
					.then((beamsClient) => beamsClient.getDeviceId())
					.then((deviceId) =>
						console.log("Successfully registered with Beams. Device ID:", deviceId)
					)
					.then(() => beamsClient.setUserId(resp.data.user.id.toString(), {
						fetchToken: () => Promise.resolve({ token:resp.data.user.beams_token.token }),
					}))
					.catch(console.error);
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
