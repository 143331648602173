import axios from 'axios'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const getBaseUrl = () => {
    let url;
    switch(process.env.NODE_ENV) {
      case 'production':
        url = 'https://hub.kde-kurier.com';
        break;
      case 'development':
      default:
        url = 'http://localhost:80';
    }
  
    return url;
}

const publicRoutes = ['/sanctum/token', '/sanctum/admin-login', '/login', '/register', '/password/reset', '/reset-password', '/sign-up', '/forgot-password']

const BaseService = axios.create({
    timeout: 60000,
    baseURL: getBaseUrl(),
})

BaseService.interceptors.request.use(
    (config) => {
        const isExtRequest = config.url.startsWith('/ext/');
        const isPublicRoute = publicRoutes.some(route => config.url.includes(route));

        if (!isExtRequest && !isPublicRoute) {
            const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
            const persistData = deepParseJson(rawPersistData) || {}
            const accessToken = persistData.auth?.session?.token

            if (accessToken) {
                config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
            } else {
                console.warn('No authentication token found for protected request')
                store.dispatch(onSignOutSuccess())
                
                // Cancel the request
                const source = axios.CancelToken.source();
                config.cancelToken = source.token;
                source.cancel('Request cancelled due to missing authentication token');
            }
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService
