import { PERSIST_STORE_NAME } from "constants/app.constant"
import deepParseJson from "utils/deepParseJson";
import ApiService from "./ApiService"

//? Since this file is called by the LanguageSelector before login the persistData must be called again after login:
const getPersistedData = () => {
  const persistData = deepParseJson(localStorage.getItem(PERSIST_STORE_NAME));
  return persistData;
};

export async function apiGetSettingData () {
    const persistData = getPersistedData();
    
    return ApiService.fetchData({
        url: '/orgs/' + persistData.auth.user.current_org + '/partner/settings/data',
        method: 'get'
    })
}

export async function apiUpdatePassword (data) {
  const persistData = getPersistedData();
 
  return await ApiService.fetchData({
      headers: {
          'Content-Type': 'multipart/form-data'
        },
      url: '/orgs/' + persistData.auth.user.current_org + '/partner/settings/update-password',
      method: 'post',
      data
  })
}

export async function apiUpdateDarkMode (data) {
  const persistData = getPersistedData();

  return await ApiService.fetchData({
      headers: {
          'Content-Type': 'multipart/form-data'
        },
      url: '/orgs/' + persistData.auth.user.current_org + '/partner/settings/update-dark-mode',
      method: 'post',
      data
  })
}

export async function apiUpdateLangMode (data) {
  const persistData = getPersistedData();
 
  return await ApiService.fetchData({
      headers: {
          'Content-Type': 'multipart/form-data'
        },
      url: '/orgs/' + persistData.auth.user.current_org + '/partner/settings/update-language',
      method: 'post',
      data
  })
}

export async function apiUpdateNotifications (data) {
  const persistData = getPersistedData();
 
  return await ApiService.fetchData({
      url: '/orgs/' + persistData.auth.user.current_org + '/partner/settings/update-notifications',
      method: 'post',
      data
  })
}