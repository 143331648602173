import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

export default function EmptySimple({className, title, text, icon}) {

  return (
    <div className={classNames("text-center", className)}>
      <FontAwesomeIcon className='text-3xl' icon={icon} />
      <h3 className="mt-2 text-sm font-semibold">{title}</h3>
      <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{text}</p>
    </div>
  )
}