import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentShift: 'offline',
}

export const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
      setShift: (state, action) => {
            state.currentShift = action.payload
        },
    },
})

export const { setShift } = shiftSlice.actions

export default shiftSlice.reducer
