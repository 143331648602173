import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import de from './lang/de.json'
import pl from './lang/pl.json'
import bg from './lang/bg.json'
import ro from './lang/ro.json'
import cz from './lang/cz.json'
import sk from './lang/sk.json'
import lv from './lang/lv.json'
import hu from './lang/hu.json'
import ru from './lang/ru.json'
import it from './lang/it.json'
import lt from './lang/lt.json'
import nl from './lang/nl.json'
import sl from './lang/sl.json'
import sr from './lang/sr.json'
import appConfig from 'configs/app.config'

const resources = {
    en: { translation: en },
    de: { translation: de },
    pl: { translation: pl },
    bg: { translation: bg },
    ro: { translation: ro },
    cz: { translation: cz },
    sk: { translation: sk },
    lv: { translation: lv },
    hu: { translation: hu },
    ru: { translation: ru },
    it: { translation: it },
    lt: { translation: lt },
    nl: { translation: nl },
    sl: { translation: sl },
    sr: { translation: sr },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    de: () => import('dayjs/locale/de'),
    pl: () => import('dayjs/locale/pl'),
    bg: () => import('dayjs/locale/bg'),
    ro: () => import('dayjs/locale/ro'),
    cz: () => import('dayjs/locale/cs'),
    sk: () => import('dayjs/locale/sk'),
    lv: () => import('dayjs/locale/lv'),
    hu: () => import('dayjs/locale/hu'),
    ru: () => import('dayjs/locale/ru'),
    it: () => import('dayjs/locale/it'),
    lt: () => import('dayjs/locale/lt'),
    nl: () => import('dayjs/locale/nl'),
    sl: () => import('dayjs/locale/sl'),
    sr: () => import('dayjs/locale/sr'),
}

export default i18n